* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}


/* customized scroll bar */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: o.5;
}

::-webkit-scrollbar-thumb {
  background-color: #B85EB3;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4DBEE9;
}