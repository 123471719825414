/* .Navbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000000;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.nav-logo {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  font-family: "Bitter", serif;
  font-style: normal;
}

.Navbar>.nav-items>a {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.9;
}

.Navbar>.nav-items>a:hover {
  opacity: 1;

}

.Navbar>.nav-items>a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
}

.Navbar>.nav-items>a:hover::before {
  width: 100%;
}

.Navbar>.nav-toggle {
  display: none;
}

@media (max-width: 700px) {
  .Navbar>.nav-items {
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    background: black;
    left: 0;
    width: 100%;
    height: auto;
    transform: translateX(-100%);
    transition: all .45s;
  }

  .Navbar>.nav-items>a::before {
    background: transparent;
  }

  .Navbar>.nav-items.open {
    transform: translateX(0);
  }

  .Navbar>.nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle>.bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle>.bar::before,
  .nav-toggle>.bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle>.bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle>.bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open>.bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open>.bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open>.bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }


} */




.Navbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f0f0;
  position: fixed;
  width: 100%;
  z-index: 2;
  /* padding: 0 20px; */
}

.nav-logo {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  font-family: "Bitter", serif;
  font-style: normal;
}

.navBarLogo {
  height: 80px;
  width: auto;
}

.nav-items {
  display: flex;
  align-items: center;
}

.Navbar>.nav-items>a {
  color: #000000;
  font-weight: 550;
  font-size: 16px;
  text-decoration: none;
  margin: 15px;
  position: relative;
  /* background-color: red; */
  opacity: 0.9;
}

.Navbar>.nav-items>.dropdown>a {
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  font-weight: 550;
  /* padding-left: 50px; */
  /* background-color: red; */
}

.Navbar>.nav-items>a:hover,
.Navbar>.nav-items>.dropdown>a:hover {
  opacity: 1;
}

.Navbar>.nav-items>a::before,
.Navbar>.nav-items>.dropdown>a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #000000;
  transition: all 0.45s;
}

.Navbar>.nav-items>a:hover::before,
.Navbar>.nav-items>.dropdown>a:hover::before {
  width: 100%;
}

.Navbar>.nav-toggle {
  display: none;
}

.Navbar .dropdown {
  position: relative;
}

.Navbar .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #f3f0f0;
  flex-direction: column;
  width: 200px;
  z-index: 3;
  padding-top: 1px;
}

.Navbar .dropdown-menu a {
  padding: 10px;
  color: #000000;
  text-decoration: none;
  display: center;
  font-weight: 550;
  opacity: 0.9;
}

.Navbar .dropdown-menu a:hover {
  background: #b1acac;
}

.Navbar .dropdown-menu.open {
  display: flex;
}

@media (max-width: 700px) {
  .Navbar>.nav-items {
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    background: #f3f0f0;
    left: 0;
    width: 100%;
    height: auto;
    transform: translateX(-100%);
    transition: all .45s;
  }

  .navBarLogo {
    height: 60px;
    width: auto;
  }

  .Navbar>.nav-items>.dropdown {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .Navbar>.nav-items>a::before,
  .Navbar>.nav-items>.dropdown>a::before {
    background: transparent;
  }

  .Navbar>.nav-items.open {
    transform: translateX(0);
  }

  .Navbar>.nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle>.bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #000000;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle>.bar::before,
  .nav-toggle>.bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #000000;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle>.bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle>.bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open>.bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open>.bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open>.bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }

  .Navbar .dropdown-menu {
    position: sticky;
    width: 100%;
    padding-top: 0;
  }

  .Navbar .dropdown-menu a {
    text-align: center;
  }
}